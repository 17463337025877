import { JSX } from "solid-js";
import styles from "./IconButton.module.css";
import * as KoButton from "@kobalte/core/button";

export default function IconButton(props: {
  children: JSX.Element;
  class?: string;
  disabled?: boolean;
  label?: string;
  onClick: () => void;
}) {
  return (
    <KoButton.Root
      disabled={props.disabled}
      class={props.class || styles.button}
      role="button"
      aria-label={props.label}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        !props.disabled && props.onClick();
      }}
    >
      {props.children}
    </KoButton.Root>
  );
}
